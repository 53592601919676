import { Component, OnInit, Input, NgZone } from '@angular/core';

import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';

import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '../../services/data.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { DashboardService } from '../../services/dashboard.service';
import { Global } from '../../_constants/global.variables';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import _ from 'lodash';
import { ClipboardService } from 'ngx-clipboard';
import { UtilityService } from '../../services/utility.service';
import { IGlobal } from '../../_models/global.model';
import { ITag } from '../../_models/tag.model';
import { ITagNamePrefixSubject } from '../../_models/tag-name-prefix-subject.model';

@Component({
	selector: 'lib-pbb-quick-view',
	templateUrl: './pbb-quick-view.component.html',
	styleUrls: ['./pbb-quick-view.component.scss'],
})
export class PbbQuickViewComponent implements OnInit {
	@Input() parentAsset: any;
	@Input() parentTagIds: any;
	@Input() widgetObject: any;
	fullDataCacheSubscription: any;
	theme: string;
	assetTags: any;
	isDataLoading: boolean;
	tagGraphSingleModalSubscription: Subscription;
	private swalWithBootstrapButtons: any;
	public camera: any;
	public label = {
		visible: false,
	};
	public progressStyles: { [key: string]: any } = {
		background: 'darkgrey',
	};

	public global: IGlobal = Global;

	public viewCamera = false;
	public widgetGroupSettings: any;

	public ObservationIdMappingObjectForTrueFalseValues: any = {
		UnitStatus: [12245], // Unit Status
		PowerOn: [4504, 12347, 12374], //Power On
		Moving: [54271],
		AutoLevelStatus: [3782],
		CanopyDown: [1822],
		SlowDownActive: [4521],
		AcfStatus: [15260],
		VentilatorStatus: [2032],
		FloodLight: [3796],
		GateSign: [13696],
		FloorHeat: [3922],
		WindowHeat: [14488],
		DeadmanSwitch: [15575],
		SwingDoorLeft: [56766],
		SwingDoorRight: [56767],
		ServiceStairDoor: [56775],
		ElevatingRotundaSterileDoor: [13608],
		TerminalDoor: [12431],
		BagLiftInterlock: [13453],
		WheelchairInterlock: [13548],
		Hoist1: [3783],
		Hoist2: [13571],
		Hoist3: [56756],
		VdgsInterlock: [13183, 13005],
		EngineSensor: [12467],
		WingRootSensor: [12454],
		LeftIRSlowDown: [56759],
		RightIRSlowDown: [56761],
		CenterIRSlowDown: [13498],
		ForwardSlowDown: [1904],
		ReverseSlowDown: [4511],
		AutolevelAlarm: [3830],
		EstopMain: [12384],
		EstopDriveRight: [13359],
		EstopDriveLeft: [13358],
		EstopElevatingRotunda: [56760],
		SmokeDetector: [15227],
		VerticalRack: [14378],
		VerticalLeftRack: [13553],
		VerticalRightRack: [13503],
		SafetyShoeNotConnected: [19075],
		HorizInverterFault: [56379],
		AcfFault: [12409],
		SpacerBypassPB: [12987],
		VerticalOverloadLeft: [3868],
		VerticalOverloadRight: [3869],
		CabLeftWeldFault: [12393, 14139],
		CabRightWeldFault: [12387],
		MainWeldFault: [12397],
		VerticalUpWeldFault: [12403],
		VerticalDownWeldFault: [3545],
		HydraulicMotorWeldFault: [12401],
		MotionEnableWeldFault: [12461],
		CabLeftLimit: [12410],
		CabLeftUltimate: [12385],
		CabRightLimit: [12407],
		CabRightUltimate: [12392],
		SwingLeftLimit: [12440],
		SwingRightLimit: [3499],
		SwingUltimate: [12404],
		VerticalDownLimit: [12455],
		VerticalDownUltimate: [12406],
		VerticalUpLimit: [12452],
		VerticalUpUltimate: [12400],
		HorizontalExtendLimit: [12418],
		HorizontalExtendUltimate: [54299],
		HorizontalRetractLimit: [12419],
		HorizontalRetractUltimate: [3767],
		LimitsDisabled: [19062],
		HydraulicMotorOverload: [12458],
		ElevatingRotundaInverterFault: [56796],
		SlopeUpLimit: [12443],
		SlopeDownLimit: [13475],
		SlopeLimit: [56758],
		LeftSpacerLimit: [14672],
		RightSpacerLimit: [14688],
	};

	public ObservationIdMappingObjectForParseFloatValues: any = {
		HorizExtend: [1937],
		Slope: [3945],
		Height: [3824],
		WheelBogieLimitLow: [4461],
		WheelBogieLimitHigh: [4466],
		CabLimitLow: [12716],
		CabLimitHigh: [12773],
		HeightLimitLow: [2044],
		HeightLimitHigh: [2045],
		SwingLimitLow: [2042],
		SwingLimitHigh: [2043],
		JoystickLeftRight: [14096],
		JoystickForwardReverse: [14104],
		ExtendlimitLow: [14844],
		ExtendlimitHigh: [14841],
	};

	runtimeUpdateInterval: NodeJS.Timeout;

	private allStdIds = [
		12245, 4504, 12347, 12374, 54271, 3782, 1822, 4521, 1937, 3945, 3824,
		15260, 2032, 3796, 13696, 3922, 14488, 15575, 56766, 56767, 56775,
		13608, 12431, 13453, 13548, 3783, 13571, 56756, 12467, 12454, 56759,
		56761, 13498, 1904, 4511, 3830, 12384, 13359, 13358, 56760, 15227,
		14378, 13553, 13503, 19075, 56379, 12409, 12987, 3868, 3869, 12393,
		14139, 12387, 12397, 12403, 3545, 12401, 12461, 12410, 12385, 12407,
		12392, 3499, 12440, 12404, 12455, 12406, 12452, 12400, 12418, 54299,
		12419, 3767, 19062, 12458, 56796, 12443, 13475, 56758, 14672, 14688,
		4461, 4466, 12716, 12773, 2044, 2045, 2042, 2043, 14096, 14104, 14844,
		14841, 13183, 13005,
	];
	colorChangedSubscription: any;
	cacheJbtStandardObsLookup: any;
	cacheTagLookup: any;
	subscriptions: Subscription[] = [];
	private componentName: string = 'pbb-quick-view-tab: ';
	tooltipInformationObject: any = {};
	public guid: string;
	
	constructor(
		public dataService: DataService,
		private signalRCore: SignalRCoreService,
		public dialog: MatDialog,
		private dashboardService: DashboardService,
		private sanitizer: DomSanitizer,
		private clipboardApi: ClipboardService,
		private utilityService: UtilityService,
		private zone: NgZone
	) {
		this.swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success',
				cancelButton: 'btn btn-danger',
			},
			buttonsStyling: false,
		});
	}

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isDataLoading = true;
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						if (Global.Theme === 'light') {
							this.theme = 'light';
						} else if (Global.Theme === 'dark') {
							this.theme = 'dark';
						}
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			if (Global.Theme === 'light') {
				this.theme = 'light';
			} else if (Global.Theme === 'dark') {
				this.theme = 'dark';
			}
			this.initialize();
		}

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((theme: any) => {
				this.theme = theme;
			});

		
	}
	
	// [matTooltipPosition]="'above'" [matTooltip]="tooltipInformation('SwingRightLimit')"
	tooltipInformation(controllerName) {
		this.tooltipInformationObject = {
			title: '',
			TagId: '',
			TagName: '',
			ListOfJBTSTDIDSUsed: '',
			JBTStdId: '',
			JBTStdName: '',
			Value: '',
			ValueWhenActive: '',
		};
		if (Global.User.DebugMode) {
			for (const key in this
				.ObservationIdMappingObjectForTrueFalseValues) {
				if (key == controllerName) {
					this.tooltipInformationObject.ListOfJBTSTDIDSUsed =
						this.ObservationIdMappingObjectForTrueFalseValues[
							controllerName
						];
					let foundTag = this.assetTags.find((t) =>
						this.ObservationIdMappingObjectForTrueFalseValues[
							key
						].includes(t.JBTStandardObservationId)
					);
					if (foundTag !== undefined) {
						this.tooltipInformationObject = {
							title: foundTag.Name,
							TagId: foundTag.TagId,
							TagName: foundTag.TagName,
							ListOfJBTSTDIDSUsed:
								this
									.ObservationIdMappingObjectForTrueFalseValues[
									controllerName
								],
							JBTStdId: foundTag.JBTStandardObservationId,
							JBTStdName: foundTag.Name,
							Value: foundTag.Value,
							ValueWhenActive: foundTag.ValueWhenActive,
						};
						return;
					}
				}
			}

			for (const key in this
				.ObservationIdMappingObjectForParseFloatValues) {
				if (key == controllerName) {
					this.tooltipInformationObject.ListOfJBTSTDIDSUsed =
						this.ObservationIdMappingObjectForParseFloatValues[
							controllerName
						];
					let foundTag = this.assetTags.find((t) =>
						this.ObservationIdMappingObjectForParseFloatValues[
							key
						].includes(t.JBTStandardObservationId)
					);
					if (foundTag !== undefined) {
						this.tooltipInformationObject = {
							title: foundTag.Name,
							TagId: foundTag.TagId,
							TagName: foundTag.TagName,
							ListOfJBTSTDIDSUsed:
								this
									.ObservationIdMappingObjectForParseFloatValues[
									controllerName
								],
							JBTStdId: foundTag.JBTStandardObservationId,
							JBTStdName: foundTag.Name,
							Value: foundTag.Value,
							ValueWhenActive: foundTag.ValueWhenActive,
						};
						return;
					}
				}
			}
		}
	}

	onRightClick(event, controllerName) {
		console.log('right click');
		event.preventDefault();
		if (this.tooltipInformationObject.TagId !== '') {
			let stringToReturn =
				'TagId: ' +
				this.tooltipInformationObject.TagId +
				' TagName: ' +
				this.tooltipInformationObject.TagName +
				' ListOfJBTSTDIDSUsed: ' +
				this.tooltipInformationObject.ListOfJBTSTDIDSUsed +
				' JBTSTDID: ' +
				this.tooltipInformationObject.JBTStdId +
				' JBTStdName: ' +
				this.tooltipInformationObject.JBTStdName +
				' Value: ' +
				this.tooltipInformationObject.Value +
				' ValueWhenActive: ' +
				this.tooltipInformationObject.ValueWhenActive;
			this.clipboardApi.copyFromContent(stringToReturn);
			this.utilityService.showToastMessageShared({
				type: 'success',
				message: 'Text Copied to Clipboard.',
				title: 'Copied',
			});
		}
	}

	initialize() {
		console.log('Getting tags....');
		this.cacheJbtStandardObsLookup =
			this.dataService.cache.jbtStandardObservationsObject;
		this.cacheTagLookup = this.dataService.cache.tagsObject;
		this.dataService
			.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
				this.parentAsset.Id.toString(),
				false,
				this.allStdIds.toString()
			)
			.subscribe((data) => {
				let gateSystemId = this.parentAsset.ParentSystemId
					? this.parentAsset.ParentSystemId
					: this.parentAsset.GateId;
				Global.User.DebugMode &&
					console.log(
						': dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O',
						data
					);
				this.dataForDisplay(data);
				this.dataService
					.SQLActionAsPromise(
						'API.GetCamerasByParentSystemId ' + gateSystemId
					)
					.then((cameraInfo: any) => {
						if (cameraInfo.length > 0) {
							this.camera = cameraInfo[0];
							this.camera.trustedSourceURL =
								this.sanitizer.bypassSecurityTrustResourceUrl(
									this.camera.HLSSourceURL +
										'&accessToken=' +
										encodeURIComponent(
											Global.User.currentUser
												.ODataAccessToken
										)
								);
						}
					});
				this.isDataLoading = false;
				this.getSignalRUpdates();
			});
	}

	returnBackgroundColorBasedOffValue(value: any) {
		let progressStylesBasedOffValue: { [key: string]: any } = {
			background: '',
		};
		if (value <= 36) progressStylesBasedOffValue.background = 'darkgrey';
		if (value > 36 && value <= 650)
			progressStylesBasedOffValue.background = 'limegreen';
		if (value > 650) progressStylesBasedOffValue.background = 'red';
		return progressStylesBasedOffValue;
	}

	switchView(view: any) {
		this.viewCamera = view;
	}

	dataForDisplay(data: any) {
		this.assetTags = data.map((t) => ({
			TagId: t.Id != undefined ? t.Id : t.TagId,
			Name:
				t.JBTStandardObservation?.Name != undefined
					? t.JBTStandardObservation?.Name
					: this.cacheJbtStandardObsLookup[t.JBTStandardObservationId]
							?.Name != undefined
					? this.cacheJbtStandardObsLookup[t.JBTStandardObservationId]
							?.Name
					: this.cacheTagLookup[t.Id != undefined ? t.Id : t.TagId]
							?.ShortTagName,
			JBTStandardObservationId: t.JBTStandardObservationId,
			JavascriptDate: new Date(t.DateInMilliseconds),
			Value: t.Value != undefined ? t.Value : t.LastObservationTextValue,
			ValueWhenActive: t.ValueWhenActive,
			TagName: t.Name,
		}));

		for (const key in this.ObservationIdMappingObjectForTrueFalseValues) {
			this.assetTags[key] = false;
			this.evaluateTagForDebugging(key);
			this.assetTags[key] = this.evaluateTagValueVsValueWhenActive(
				this.assetTags.find((t) =>
					this.ObservationIdMappingObjectForTrueFalseValues[
						key
					].includes(t.JBTStandardObservationId)
				)
			);
		}

		for (const key in this.ObservationIdMappingObjectForParseFloatValues) {
			this.assetTags[key] = false;
			this.evaluateTagForDebugging(key);
			this.assetTags[key] = this.evaluateTagValueForParseFloat(
				this.assetTags.find((t) =>
					this.ObservationIdMappingObjectForParseFloatValues[
						key
					].includes(t.JBTStandardObservationId)
				)
			);
		}
		console.log(this.assetTags);

		//Get Run Time Data if the Unit is On
		if (this.assetTags.UnitStatus) {
			this.getRuntimeData();
			this.runtimeUpdateInterval = setInterval(() => {
				// Update the runtime values every minute
				this.updateRuntimes();
			}, 1000 * 60);
		}
	}

	evaluateTagForDebugging(key: any) {
		if (key === 'UnitStatus') {
			console.log(this.assetTags[key]);
		}
	}

	evaluateTagValueVsValueWhenActive(tagObject) {
		if (_.isNil(tagObject)) {
			return false;
		} else if (!_.isNil(tagObject.ValueWhenActive)) {
			if (tagObject.Value == tagObject.ValueWhenActive) {
				return true;
			} else {
				return false;
			}
		} else {
			if (tagObject.Value == 1) {
				return true;
			} else {
				return false;
			}
		}
	}

	evaluateTagValueForParseFloat(tagObject) {
		if (_.isNil(tagObject)) {
			return '-';
		} else {
			return parseFloat(tagObject.Value).toFixed(1);
		}
	}

	dataUpdate(dataUpdateTagObj: any) {
		for (const key in this.ObservationIdMappingObjectForTrueFalseValues) {
			let ifFound = this.ObservationIdMappingObjectForTrueFalseValues[
				key
			].includes(parseInt(dataUpdateTagObj.JBTStandardObservationId));
			if (ifFound) {
				this.assetTags[key] =
					this.evaluateTagValueVsValueWhenActive(dataUpdateTagObj);
				if (key === 'UnitStatus' && this.assetTags[key] === true) {
					this.getRuntimeData();
					this.runtimeUpdateInterval = setInterval(() => {
						// Update the runtime values every minute
						this.updateRuntimes();
					}, 1000 * 60);
				}
			}
		}

		for (const key in this.ObservationIdMappingObjectForParseFloatValues) {
			let ifFound = this.ObservationIdMappingObjectForParseFloatValues[
				key
			].includes(parseInt(dataUpdateTagObj.JBTStandardObservationId));
			if (ifFound) {
				this.assetTags[key] =
					this.evaluateTagValueForParseFloat(dataUpdateTagObj);
			}
		}
	}

	openTagGraphSingle(observationIdArray): void {
		let tagObject = this.assetTags.find((t) =>
			observationIdArray.includes(t.JBTStandardObservationId)
		);
		if (tagObject === undefined) {
			this.swalWithBootstrapButtons
				.fire({
					title: 'Error',
					text: "Tag Object Doesn't Exist",
					showCancelButton: false,
					confirmButtonText: 'Ok',
					reverseButtons: false,
				})
				.then(() => {});
		} else {
			if (this.widgetObject == undefined) {
				this.widgetObject = tagObject;
				this.widgetObject.TimeZoneId = null;
			}

			const tagGraphSingleModal = this.dialog.open(
				DialogModalParentComponent,
				{
					width: Global.isMobile ? '90%' : '60%',
					height: Global.isMobile ? '90%' : '80%',
					data: {
						TagId: tagObject.TagId,
						widgetNameDisplay: 'Tag Graph',
						WidgetName: 'tag-graph',
						isDisplayDataLive: true,
						timeZoneType:
							this.dashboardService.determineTimeZoneType(
								this.widgetObject
							),
					},

					maxWidth: '100vw',
					maxHeight: '100vh',
				}
			);
			this.tagGraphSingleModalSubscription = tagGraphSingleModal
				.afterClosed()
				.subscribe((result) => {
					Global.User.DebugMode &&
						console.log('The modal was closed');
					this.tagGraphSingleModalSubscription.unsubscribe();
				});
		}
	}

	getRuntimeData() {
		var UnitOnTag: ITag = this.dataService.cache.assetsObject[this.parentAsset.Id].Tags.firstOrDefault((tag:ITag)=> { return tag.JBTStandardObservationId == 12245 });
		if (UnitOnTag == null) {
			// Get Runtime Data
			let statement = `GSGetRuntimeForAssetsByAssetId @assetId = '${this.parentAsset.Id}'`;
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				Global.User.DebugMode &&
					console.log(statement + ' data = %O', data);
				this.assetTags.RunTime =
					data != undefined
						? Math.abs(
								moment
									.utc()
									.diff(moment.utc(data[0].UnitOnDate), 'minutes')
						) + ' min'
						: '';
				this.assetTags.PBBOnDate =
					data != undefined ? data[0].UnitOnDate : '';
			});
		}
		else {
			if (UnitOnTag.Value == 1) {
				//console.log("current UTC date: " + moment(new Date()) + ", moment.utc(): " + moment.utc() + ", Unit On Tag JavascriptDate: " + UnitOnTag.JavascriptDate + ", moment.utc(UnitOn.JavascriptDate): " + moment.utc(UnitOnTag.SiteDateFull));
				this.assetTags.RunTime = Math.abs(moment.utc().diff(moment.utc(UnitOnTag.JavascriptDate), 'minutes')) + ' min';
				this.assetTags.PBBOnDate = UnitOnTag.JavascriptDate;
			}
			else {
				this.assetTags.RunTime = "";
				this.assetTags.PBBOnDate = "";
			}
		}
	}


	updateRuntimes() {
		if (this.assetTags.UnitStatus)
			this.assetTags.RunTime =
				Math.abs(
					moment
						.utc()
						.diff(moment.utc(this.assetTags.PBBOnDate), 'minutes')
				) + ' min';
	}

	getSignalRUpdates() {
		let assetObjectInCache =
			this.dataService.cache.assetsObject[this.parentAsset.Id];
		
		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}
		
		Global.User.DebugMode && console.log(this.componentName + ": widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: "PBB Quick View", 
										 TagNamePrefix: [ tagNamePrefixesString ]  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				var activeSubject = this.dataService.activeSubjects.firstOrDefault((subject:ITagNamePrefixSubject) => { return subject.Id == this.guid });
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					var tagWeCareAbout = this.assetTags.firstOrDefault((assetTag:any) => { return assetTag.TagId == tag.Id });
					if (tagWeCareAbout != null) {
						tagWeCareAbout.Value = tag.Value;
						this.dataUpdate(tag);
					}
				});
			});
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + "ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.colorChangedSubscription) {
			this.colorChangedSubscription.unsubscribe();
		}
		if (this.runtimeUpdateInterval) {
			clearInterval(this.runtimeUpdateInterval);
		}
		if (this.tagGraphSingleModalSubscription !== undefined) {
			this.tagGraphSingleModalSubscription.unsubscribe();
		}
	}
}
